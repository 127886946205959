<template>
  <div class="container-md p-lg-5">
    <div class="row p-lg-5">
      <div class="col-md-12 col-lg-6 order-lg-1 p-3 order-2 align-self-end">
         
        <vue3-video-player 
          :cover="require('@/assets/images/whycoading.png')" alt="Why Hands on Learning at Tinkerly"
          src="https://lt.tinker.ly/upload/betacode/whycoading.mp4"
        ></vue3-video-player>
          
      </div>
      <div class="col-md-12 col-lg-5 align-self-end p-3 order-lg-2 order-1" style="position: relative;">
        
        <abs-image img="group228.jpg" left="2%" top="60px"/>
        <abs-image img="Group226.webp"  left="84%" top="-22%"/>
         <div style="height:100px;width:15px"/>
         <h3>Why Coding Classes<br />with STEM Toys?</h3>
        <h4>
          Start your innovative journey with us and learn to apply 21st-century
          skills!<br /><br /><br />
          Become a Problem-solver | Smart Thinker | Enhance Creativity |Promotes
          Logical Reasoning | Nurture Innovation
        </h4>
      </div>
    </div>
  </div>
</template>
<script>
import AbsImage from './absImage.vue'
export default {
  name: "WhyCoding",
  components:{
    AbsImage
  },
  data() {
    return {
     
    };
  },
};
</script>
<style scoped>
.vcp-container{
  height: auto !important;
}

h3 {
  font-size: 2rem;
  font-weight: 800;
  text-align: left;
  margin: 0px;
}

h4 {
  color: #828282;
  text-align: left;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 12px 0px 0px 0px;
}
</style>