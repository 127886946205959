<template>
  <div class="container-lg">
    <div class="row justify-content-center">
      <div class="col-sm-10 " style="z-index:999">
        <h3>{{ head }}</h3>
        <vue-collapsible-panel-group accordion>
          <vue-collapsible-panel
            :expanded="false"
            v-for="faq in faqs"
            :key="faq.Q"
          >
            <template #title>
            <div> <p v-html="faq.Q"></p> </div>
            </template>
            <template #content>
                <div> <p v-html="faq.A"></p> </div>
            </template>
          </vue-collapsible-panel>
        </vue-collapsible-panel-group>
      </div>
    </div>
  </div>
</template>
<script>
import {
  VueCollapsiblePanel,
  VueCollapsiblePanelGroup,
} from "@dafcoe/vue-collapsible-panel";
import "@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css";
export default {
  name: "faqs",
  props: {
    faqs: Array,
    head: String,
  },
  components: {
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
  },
};
</script>
<style>
.vcp {
  padding: 1.1%;
  margin: 20px;
  background-color: #80cacf !important;
  border-radius: 15px;
}
.vcp__header {
  color: #fff !important;

  font-size: 1.2rem;
  height: auto !important;
}
.vcp__header-title {
  font-weight: 400 !important;
}
.vcp__header:before {
  display: none;
}
.vcp__body {
  margin-left: 0px !important;
  background-color: #80cacf !important;
  border: 0px !important;
}
.vcp__body-content {
  padding: 12px !important;
  padding-top: 0px !important ;
  padding-bottom: 0px !important ;
  font-weight: 400 !important;
  color: #000 !important;
  font-size: 1.2rem;
}

h3 {
  font-size: 2rem;
  font-weight: 800;
  margin-left: 50px;
}
</style>