<template>
    <div class="main">
        <span class="close" @click="click">x</span>
        <div class="row align-items-center" style="height: 100%;">
            <div class="col" style="text-align: center;">
                <Btn_outlineBlack DisplayText="Curriculum + Pricing" cls="curriculum_price_btn" />
                <br/>
            <book-demobtn cls="book_demo_btn" />
            </div>
        </div>
    </div>
</template>
<style scoped>
.main{
   position: fixed;
    left: 10%;
    bottom: 10%;
    top: 0px;
    right: 0px;
    background: #333333;
    z-index: 999;
    min-height: 100px;
    height:150px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}
.close{
    color: #fff;
    padding: 0px 25px;
    font-size: 2rem;
    font-weight: 500;
    cursor: pointer;
    position: fixed;
    z-index: 999999;
    top: 0px;
    right: -15px;
}
</style>
<style>
.book_demo_btn{
    width: 80%;
    background: #FFFFFF;
    color: #333333;
    border-color: #ffffff !important;
}
.curriculum_price_btn{
    width: 80%;
    background: #333333;
    color: #ffffff;
    border-color: #ffffff !important;
}
.buy_a_course_btn{
 width: 80%;
}
</style>
<script>
import Btn_outlineBlack from "./btn_outlineBlack.vue";
import BookDemobtn from "./bookDemobtn.vue";
export default {
     components: {
    Btn_outlineBlack,
    BookDemobtn
     },
     props:['click','buyCourseMethod','buyCoursetrackId'],
  name: 'hamburger'
}
</script>