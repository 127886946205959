// 860 tab
// 680 mobile
<template>
  <div>
    <section style="background: #fccc38">
      <div class="container-lg">
        <div class="row pt-2 pb-2">
          <div class="col-auto">
            <img style="height: 50px" src="@/assets/india/image/logo.png" alt="Tinkerly Logo"/>
          </div>
          <div class="col" style="text-align: right; line-height: 60px" v-if="$windowWidth>680">
            <Btn_outlineBlack @click="scroleToCourse" DisplayText="Curriculum + Pricing" />
            <book-demobtn location="201" />
           
          </div>
          <div class="col" style="text-align: right; line-height: 60px" v-else>
             <hamburger :click='toggleMenu' v-if='showMenu' :buyCourseMethod="openBuyCoursePage" buyCoursetrackId="203" />
            <img class="hamburger_btn"   @click="toggleMenu" src="@/assets/images/barsymbol.png" alt="barsymbol"/>
          </div>
        </div>
      </div>
    </section>
    <section style="background: #fccc38;overflow: visible;">
      <cover />
    </section>
    <section>
      <why-coding />
    </section>
    <section>
      <tag-lines />
    </section>
    <section style="background: #fccc38" class="py-3">
      <best-learning-experience />
    </section>
    <section class="p-lg-5 p-3 pt-5 " style="background:#F2F2F2" ref="course">
     <course :buyCourseMethod="openBuyCoursePage" buyCoursetrackId="0"/>
    </section>
    <section>
      <student-work />
    </section>
    <section>
      <with-us />
    </section>
    <section style="background: #fccc38">
      <div class="container-lg pt-5">
        <taking-steps
          head="Taking steps to turn dreams into reality with us!"
          subHead="Your child s innovative journey begins now"
        />
        <div class="p-5" style="text-align: center">
          <book-demobtn location="207" />
        </div>
      </div>
    </section>
    <section style="background: #f2f2f2">
      <track-route />
    </section>
    <section style="position: relative" class="container-md mb-5 mt-5">
      <teacher-box
        head="Teachers"
        subHead="Our Expert Instructors from the world s top universities!"
      />
    </section>

    <section style="position: relative" class="container-md mb-5 mt-5">
      <testimonial
        :testimonials="testimonialData"
        head="Parents & Students Believe In Us! "
      />
    </section>

    <section style="position: relative" class="p-sm-4 p-md-5 p-0">
      <abs-image img="vector127.webp" size="80px" left="70%" top="2%" />
      <abs-image img="vector127.webp" size="80px" left="-35px" top="15%" />
      <abs-image img="vector127.webp" size="80px" left="60%" top="95%" />
      <Faqs :faqs="faqsData" head="FAQs" />
    </section>

    <section class="pt-5">
      <Footer />
    </section>
  </div>
</template>

<script>
import "@/assets/css/bootstrap_5.2.min.css";
import faqs_data from "@/assets/jsons/faq.json";
import testimonial_data from "@/assets/jsons/testimonial.json";
import Btn_outlineBlack from "../Compes/btn_outlineBlack.vue";
import Footer from "../Compes/footer.vue";
import Faqs from "../Compes/Faqs.vue";
import AbsImage from "../Compes/absImage.vue";
import testimonial from "../Compes/testimonial.vue";
import TeacherBox from "../Compes/teacherBox.vue";
import takingSteps from "../Compes/takingSteps.vue";
import cover from "../Compes/cover.vue";
import WhyCoding from "../Compes/whyCoding.vue";
import tagLines from "../Compes/tagLines.vue";
import BestLearningExperience from "../Compes/bestLearningExperience.vue";
import BookDemobtn from "../Compes/bookDemobtn.vue";
import trackRoute from "../Compes/trackRoute.vue";
import withUs from "../Compes/withUs.vue";
import studentWork from "../Compes/StudentWork.vue";
import Course from '../Compes/course.vue';
import hamburger from '../Compes/hamburger.vue'
import {trackerLeadSquaredEvent,trackerFBPixel} from '@/assets/js/TinkerlyTracking.js';
export default {
  name: "Home",
  components: {
    Btn_outlineBlack,
    Footer,
    Faqs,
    AbsImage,
    testimonial,
    TeacherBox,
    takingSteps,
    cover,
    WhyCoding,
    tagLines,
    BestLearningExperience,
    BookDemobtn,
    trackRoute,
    withUs,
    studentWork,
    Course,
    hamburger
  },
  data() {
    return {
      faqsData: faqs_data,
      testimonialData: testimonial_data,
      showMenu:false
    };
  },
  methods:{
    toggleMenu(){
      this.showMenu = !this.showMenu;
    },
    scroleToCourse(){
      var course = this.$refs.course;
      if (course) {
                var top = course.offsetTop;
                window.scrollTo(0, top);
      }
      trackerLeadSquaredEvent(201);
      trackerFBPixel('Price');
                 this.$gtag.event("conversion", {
              send_to: "AW-810557807/qRCnCM2mz4UDEO_CwIID",
            });
    },
    openBuyCoursePage(trackId){
if(trackId != 0 )
trackerLeadSquaredEvent(trackId);
this.$router.push({ name: 'PayNow',params: { username: this.$route.params.username }});
    }
        },
        mounted: function () {
            window.__lc = window.__lc || {};
            window.__lc.license = 13188978;
            (function (n, t, c) {
                function i(n) { return e._h ? e._h.apply(null, n) : e._q.push(n) }
                var e = {
                    _q: [], _h: null, _v: "2.0", on: function () { i(["on", c.call(arguments)]) }, once: function () {
                        i(["once", c.call(arguments)])
                    }, off: function () { i(["off", c.call(arguments)]) },
                    get: function () {
                        if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
                        return i(["get", c.call(arguments)])
                    }, call: function () { i(["call", c.call(arguments)]) }, init: function () {
                        var n = t.createElement("script");
                        n.async = !0, n.type = "text/javascript",
                            n.src = "https://cdn.livechatinc.com/tracking.js", t.head.appendChild(n)
                    }
                };
                !n.__lc.asyncInit && e.init(), n.LiveChatWidget = n.LiveChatWidget || e
            }(window, document, [].slice))
        }
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  font-family: "Poppins", sans-serif;
}

section{
  overflow: hidden;
}

.hamburger_btn{
  cursor: pointer;
}

</style>