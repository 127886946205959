<template>
    <btn_fillBlack DisplayText="Book a free demo" :cls="cls"  @click="bookDemo"/>
</template>
<script>
import btn_fillBlack from './btn_fillBlack.vue'
import {trackerLeadSquaredEvent} from '@/assets/js/TinkerlyTracking.js';
export default {
  components: { btn_fillBlack },
    name:'BookDemoBtn',
    props: {
    cls:{
      type: String
    },
    location:{
      type: Number
    }
  },
  methods:{
    bookDemo(){
      trackerLeadSquaredEvent(this.Number);
      this.$router.push({ name: 'BookDemo',params: { username: this.$route.params.username }});
    }
  }
}
</script>