<template>
    <div class="row justify-content-center">
        <div class="col-12">
           <h3> {{head}}</h3>
           <h4>{{subHead}}</h4>
        </div>
    </div>
         <div class="row justify-content-center" v-if="$windowWidth>1200">
        <div class="col-4"  v-for="testimonial in testimonials" :key="testimonial.text" style="" >
            <testimonialBox :backgroundColor="testimonial.bgColor"
             :imageColor="testimonial.imgBgColor" :img="testimonial.image" :text="testimonial.text"/>
        </div>
    </div>
    <div class="row" v-else>
<div class="col">
  <swiper
    @swiper="onSwiper"
    :slidesPerView="'auto'"
    :spaceBetween="20"
    class="mySwiper"
  >
  <swiper-slide style="width:400px" v-for="testimonial in testimonials" :key="testimonial.text" >
     <testimonialBox :backgroundColor="testimonial.bgColor"
             :imageColor="testimonial.imgBgColor" :img="testimonial.image" :text="testimonial.text"/>
  </swiper-slide>
    </swiper>
</div>
    </div>
</template>
<script>
import testimonialBox from './testimonialBox.vue'
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";
export default {
  components: { testimonialBox,
   Swiper,
    SwiperSlide, },
  name: 'testimonial',
   props: {
    testimonials: Array,
    head: String,
    subHead: String
  },
}
</script>

<style scoped>
h3 {
  font-size: 2rem;
  font-weight: 800;
  text-align: center;
  margin: 0px;
}

h4{
    color: #828282;
    text-align: center;
    font-size: 1.2rem;
  font-weight: 400;
  margin: 12px;
}
</style>
