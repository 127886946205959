<template>
    <button type="button" @click="$emit('buttonClick')" v-bind:class="[cls!=null?cls:'']" class="btn btn-dark btn-lg">{{DisplayText}}</button>
</template>
<script>
export default {
  name: 'btn_fillBlack',
  props: {
    DisplayText: {
      type: String
    },
    cls:{
      type: String
    }
  }
}
</script>
<style scoped>
    .btn {
        border: 2px solid #f4364c;
        margin: 0px 5px;
        border-radius: 12px;
        background-color: #f4364c;
    }
</style>

