<template>
    <div class="container-md my-5 py-5">
        <h3>Student Work</h3>
        <h4 class="mt-3 mb-5">Wonders created by our young innovators!</h4>
        <swiper @swiper="onSwiper" :slidesPerView="'auto'" :spaceBetween="30" class="mySwiper">
            <swiper-slide style="width:400px" v-for="d in Datalist" :key="d.image">
                <div class="vedio">
                    <vue3-video-player :cover="require('@/assets/images/'+d.image)"
                                       :src="'https://lt.tinker.ly/upload/betacode/'+d.video"></vue3-video-player>
                </div>
            </swiper-slide>
        </swiper>

    </div>
</template>
<script>
    import { Swiper, SwiperSlide } from "swiper/vue";
    import { Pagination, Navigation } from "swiper";
    // swiper core styles
    import 'swiper/swiper.min.css'
    // modules styles
    import 'swiper/components/navigation/navigation.min.css'
    import 'swiper/components/pagination/pagination.min.css'
import "swiper/swiper-bundle.min.css";
export default {
  name: "withuS",
  components: {
    Swiper,
    SwiperSlide,
  },
   data() {
    return {
      Datalist:[
        {
        image:'1.jpg',
        video:'1.mp4'
      },
      {
        image:'2.jpg',
        video:'2.mp4'
      },
      {
        image:'3.jpg',
        video:'3.mp4'
      },
      {
        image:'4.jpg',
        video:'4.mp4'
      },
      {
        image:'5.jpg',
        video:'5.mp4'
      }
        ], modules: [Pagination, Navigation]
    };
  },
  methods:{
        }
};
</script>
<style scoped>
h4 {
  color: #828282;
  text-align: left;
  font-size: 1.2rem;
  font-weight: 400;
}
    .vedio {
        border-radius: 10px;
        background: black;
        padding: 3px;
    }
h3 {
  font-size: 2rem;
  font-weight: 800;
  text-align: left;
  margin: 0px;
}
    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

        .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
</style>