<template>
    <img class="poss" :style="[{'top': top },{'left': left },{'width':size}]" :src="imgPath"/>
</template>
<script>
export default {
     name: "absImage",
  props: {
    top: {
      type: String
    },
    left: {
      type: String
    },
    size: {
      type: Number,
      required: true
    },
    img:{
      type: String,
      required: true
    },
  },
  data() {
  return {
    imgPath: require('@/assets/images/'+this.img)
  }
}
}
</script>
<style scoped>
.poss{
    position: absolute;
    z-index: 0;
    height: auto;
}
</style>