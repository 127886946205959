<template>
  <div class="container-md py-4 py-md-5">
    <h3>Get The Best Learning Experience With Us!</h3>
    <div class="row">
      <div class="col-md-12 col-lg-6 pt-5">
        <h4 class="mb-5">
          To provide kids with a complete 360&deg; learning experience that
          nurtures innovation, problem-solving, and logical thinking skills
          among young minds
        </h4>
          <vue3-video-player 
          :cover="require('@/assets/images/bestlearningexperience.png')" alt="Tinkerly's world of Fun and Learning for kids"
          src="https://lt.tinker.ly/upload/betacode/bestlearningexperience.mp4"
        ></vue3-video-player>
      </div>
      <div class="col-md-12 col-lg-6 pt-5 align-self-end px-4">
          <img  src="@/assets/images/group477.png" class="img-fluid" alt="Complete Learning Solution"/> 
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "bestLearningExperience",
   data() {
      return {
     
    };
  },
};
</script>
<style scoped>
h3 {
  font-size: 2rem;
  font-weight: 800;
  text-align: left;
  margin: 0px;
}
  
h4 {
  color: #6F6134;
  text-align: left;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 12px 0px;
}
    .vcp-container, .vcp-container video {
        height: auto !important;
    }
</style>