<template>
  <div class="row mt-5">
    <div class="col-12 mb-5">
      <h3>{{ head }}</h3>
      <h4>{{ subHead }}</h4>
    </div>
    <div class="col-6 col-md-4 col-xl-2 p-3" v-for="d in data" :key="d.head">
      <div class="box">
        <p class="headCount">{{ d.head }}</p>
        <img :src="getImage(d.image)"  style="height:65px;"/>
        <p class="bottomText">{{d.text}}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "takingSteps",
  props: {
    head: String,
    subHead: String,
  },
   data() {
    return {
      data: [
        {
          text:'Book a free demo',
          image:'group446.webp',
          head:'01'
        },
        {
          text:'Schedule date & time of demo',
          image:'group447.webp',
          head:'02'
        },
        {
          text:'Attend your scheduled demo',
          image:'group448.webp',
          head:'03'
        },
        {
          text:'Share your feedback with us',
          image:'group449.webp',
          head:'04'
        },
        {
          text:'Enroll in our course',
          image:'group431.webp',
          head:'05'
        },
        {
          text:'Get a Certificate accredited with STEM.org',
          image:'group451.webp',
          head:'06'
        },
      ]
      };
  },
  methods:{
    getImage(name){
          return require('@/assets/images/'+name);
    }
  }
};
</script>
<style scoped>
h3 {
  font-size: 2rem;
  font-weight: 800;
  text-align: center;
  margin: 0px;
}

h4 {
  color: #828282;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 12px;
}
.box {
  height: 230px;
  min-width: 150px;
  background: #ffffff;
  border-radius: 15px;
  margin: auto;
  padding: 20px;
}
.headCount {
  color: #7bb7ae;
  font-size: 3rem;
  font-weight: 800;
  text-align: right;
  margin: 0px;
  line-height: 45px;
}
.bottomText {
  color: #333333;
  text-align: left;
  font-size: 0.9rem;
  font-weight: 700;
  padding-top: 12px;
}
</style>