<template>
    <button type="button" v-bind:class="[cls!=null?cls:'']" class="btn btn-outline-dark btn-lg">{{DisplayText}}</button>
</template>
<script>
export default {
  name: 'btn_outlineBlack',
  props: {
    DisplayText: {
      type: String
    },
     cls:{
      type: String
    }
  }
}
</script>
<style scoped>
.btn{
    border: 2px solid black;
    margin: 0px 5px;
    border-radius: 12px;
}
</style>

